export const ASSETS_DATA =[

    {
        "idx": 0,
        "title": "Paalangal by Sivasankari",
        "subtitle": "<p>&nbsp;</p>\n\n<p>About the story:</p>\n\n<p>This story travels through three different generations and how the culture, and habits of people have changed over time, from the girl&#39;s point of view.</p>\n\n<p>&nbsp;</p>\n\n<p>About the author:</p>\n\n<p>Sivasankari&nbsp;is a renowned Tamil writer and activist. She has carved a niche for herself in the Tamil literary world during the last four decades with her works that reflect an awareness on social issues, a special sensitivity to social problems, and a commitment to set people thinking. She has many novels, novellas, short stories, travelogues, articles and biographies to her credit. Her works have been translated into several Indian languages, English, Japanese and Ukrainian. Eight of her novels have been made into films, having directed by renowned directors like K. Balachander, SP Muthuraman and Mahendran. Her novel &#39;Kutti&#39; on girl child labour, filmed by the director Janaki Viswanathan, won the President&#39;s Award. Sivasankari&#39;s novels have also been made as teleserials, and have won the national as well as regional &#39;Best Mega Serial&#39; awards. As a multi-faceted personality, she has won many prestigious awards including Kasturi Srinivasan Award, Raja Sir Annamalai Chettiyar Award, Bharatiya Bhasha Parishad Award, &#39;Woman of the year 1999-2000&#39; by the International Women&#39;s Association, and so on. &#39;Knit India Through Literature&#39; is her mega-project involving intense sourcing, research and translations of literature from 18 Indian languages, with a mission to introduce Indians to other Indians through culture and literature.</p>",
        "serialNo": "809",
        "scAddress": "0xce2b58c7350185df5d1f2ac133d034901787ee98",
        "img": "https://assetgateway.vantr.io/ipfs/Qmc2XMoTokoUyXe9FtzDhBXZ9guTvVKFMUdN4wd27anReg",
        "thumbnail": null,
        "category": "sports",
        "tokenGroup": "uD8sZ",
        "networkType": "polygon",
        "type": "doc",
        "pdfDetail":true,
        "price": [
            {
                "type": "crypto",
                "amount": 153,
                "currency": "MATIC",
                "sale": {
                    "salePrice": null,
                    "saleEndDate": null
                },
                "symbol": "S"
            }
        ]
    },

    {
        "idx": 1,
        "title": "Asset 10",
        "subtitle": "<p>ctvukjshksibjklkl;</p>",
        "serialNo": "1219",
        "scAddress": "0x5530602fd80c23ec22e9614bc5a6948761b22802",
        "img": "https://assetgateway.vantr.io/ipfs/QmXqdfZA6LzVrVybPme96yh2ZKdxKAGRa5ZqWhpXGhwcr3",
        "thumbnail": "https://assetgateway.vantr.io/ipfs/QmdxioYNMAa4YdyRqvq22hHhSgJPdhRqouH4Cz1j7t6PMC",
        "category": "sports",
        "tokenGroup": "uD8sZ",
        "networkType": "polygon",
        "type": "video",
        "price": []
    }

]