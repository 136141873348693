export const transactionDetail = {
    name: "0x12346b1a38fb959f9c60c3bd74bd03ef9bbf85a1",
    paymentInfo: "USD",
    orderDate: "5 Nov 2018",
    price: "$4500",
    productTitle: "Casablanca",
    productDescription: [
        {
            key: "Order Id",
            value: "Ix43232"
        },
        {
            key: "Created By",
            value: "Lorem Name"
        },
        {
            key: "Purchased At",
            value: "23 Aug 2020"
        }
    ],
    productImg: "https://i.ibb.co/NKMBMBy/2.jpg",
    orderStatus: 3,
    qrCodeLink: "https://www.google.com",
    // orderTimeline: [
    //     {
    //         title: "Product",
    //         date: "23-09-2018",
    //         url: "https://www.google.com"
    //     },
    //     {
    //         title: "Transaction",
    //         date: "24-09-2018",
    //         url: "https://www.google.com"
    //     },
    //     {
    //         title: "Validation",
    //         date: "26-09-2018",
    //         url: "https://www.google.com"
    //     },
    //     {
    //         title: "Alloted",
    //         date: "29-09-2018",
    //         url: "https://www.google.com"
    //     }
    // ],
    amountBreakup: [
        {
            key: "Purchase Price",
            value: "$ 4500.00"
        },
        {
            key: "Transaction Fee",
            value: "$ 100.00"
        }
    ],
    orderReciptButtons: [
        {
            key: "print",
            title: "Print",
            disabled:true
        },
        {
            key: "download",
            title: "Download",
            disabled:true
        },

    ],
}